import axios, { AxiosRequestConfig } from 'axios'
import { apiUrl } from '../data'

/**
 * 메인 화면에서 이벤트 정보 1개 가져오기
 * @returns
 */
export const getAnEvent = async () => {
  const options: AxiosRequestConfig = {
    url: `${apiUrl}/v1/boards?category=event&filter=doing&page=0&limit=1&order=lastest`,
    method: 'GET',
  }

  try {
    // @TODO: Fix interface 서버로부터 {data, message} 로 안옴
    // const result = await axios.request<ApiResponse>(options)
    const result = await axios.request(options)

    return result.data
  } catch (error) {
    throw error
  }
}

export const getMagazineData = async () => {
  const momResponse = await axios.get(`${apiUrl}/v1/boards?category=tips&page=0&limit=9999&order=lastest&tag`)
  const petResponse = await axios.get(`${apiUrl}/pet/v1/boards?category=tips&page=0&limit=9999&order=lastest&tag`)

  const merged = [...momResponse.data.data, ...petResponse.data.data]
  const result = []
  const map = new Map()
  for (const item of merged) {
    if (!map.has(item.id)) {
      map.set(item.id, true)
      result.push(item)
    }
  }
  return result.sort((a, b) => b.id - a.id)
}

export const apiGetBoardDetail = async (id: number) => {
  const options: AxiosRequestConfig = {
    url: `${apiUrl}/v1/boards/detail/${id}`,
    method: 'GET',
  }

  try {
    const result = await axios.request(options)
    return result.data.data
  } catch (error) {
    throw error
  }
}
